document.addEventListener('turbolinks:load', function() {
  $('select').not('.select2-hidden-accessible').each(function() {
    $(this).wrap('<span class=\'select-container\'></span>');
  });

  $(document).on('select2:open', () => {
    setTimeout(function() {
      document.querySelector('input.select2-search__field').focus();
    }, 100);
  });
});
